

































import {Component, Vue} from "vue-property-decorator";

@Component
export default class HomeBodyServiceIntroduceComponent extends Vue {
  // data
  private cards = [
    {
      title: "Webアプリケーション設計・開発",
      subTitle: "WEB APPLICATION DEVELOPMENT",
      content: "Java/Spring Bootを駆使し、貴社のビジネスニーズに沿った" +
               "<br/>高品質なWebアプリケーションを提供します。",
      alt: "プログラムコードの写真",
      src: "/home/service-introduce/web-application-development.jpg",
    },
    {
      title: "インフラ設計・構築",
      subTitle: "INFRASTRUCTURE CONSTRUCTION",
      content: "AWSを最大限に活用し、堅牢でスケーラブル、" +
               "<br/>かつ信頼性の高いインフラ環境を提供します。",
      alt: "５人の人がパソコンをしている写真",
      src: "/home/service-introduce/infrastructure-construction.jpg",
    },
    {
      title: "システム自動化",
      subTitle: "SYSTEM AUTOMATION",
      content: "CI/CDの導入により業務プロセスを自動化することで、" +
               "<br/>生産性の向上やヒューマンエラーの削減に貢献します。",
      alt: "何かを勉強している写真",
      src: "/home/service-introduce/system-automation.jpg",
    },
    {
      title: "ITコンサルティング",
      subTitle: "IT CONSULTING",
      content: "貴社が直面するIT課題に対して、" +
               "<br/>技術導入から運用改善まで一気通貫でサポートします。",
      alt: "何かをメモーしている写真",
      src: "/home/service-introduce/it-consulting.jpg",
    },
  ];
}
