import { render, staticRenderFns } from "./MessageFooterComponent.vue?vue&type=template&id=5c89aa45&scoped=true&"
import script from "./MessageFooterComponent.vue?vue&type=script&lang=ts&"
export * from "./MessageFooterComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c89aa45",
  null
  
)

export default component.exports