












import {Component, Vue} from "vue-property-decorator";
import {CommonNavItemType} from "@/type/common/nav";
import OrganismHeaderAndAside from "@/components/UI/organisms/OrganismHeaderAndAside.vue";

@Component({
  components: {
    OrganismHeaderAndAside,
  },
})
export default class TemplateHeaderAndAside extends Vue {
  // data
  private homeUrl = "/";

  private navItemsTop: Record<string, CommonNavItemType> = {
    // projectRequest: {
    //   title: 'Project Request',
    //   icon: 'mdi-briefcase',
    //   url: '/project-request',
    //   onClickFunction: this.redirectToProjectRequestPage,
    // },
    home: {
      title: "Home",
      icon: "mdi-home",
      url: "/",
      onClickFunction: this.redirectToHomePage,
    },
    message: {
      title: "Message",
      icon: "mdi-account-tie-voice",
      url: "/message",
      onClickFunction: this.redirectToMessagePage,
    },
    recruit: {
      title: "Recruit",
      icon: "mdi-account-multiple",
      url: "/recruit",
      onClickFunction: this.redirectToRecruitPage,
    },
    access: {
      title: "Access",
      icon: "mdi-map-marker",
      url: "/access",
      onClickFunction: this.redirectToAccessPage,
    },
  }

  private navItemsDown: Record<string, CommonNavItemType> = {
    scrollToTop: {
      title: "Scroll To Top",
      icon: "mdi-arrow-up-bold",
      url: "",
      onClickFunction: this.scrollToTop,
    },
  };

  private drawer = false;

  // method
  private toggleDrawer() {
    this.drawer = !this.drawer;
  }

  private openDrawer(): void {
    this.drawer = true;
  }

  private closeDrawer(): void {
    this.drawer = false;
  }

  private scrollToTop() {
    window.scrollTo({top: 0, behavior: "smooth"});
    this.drawer = false;
  }

  private redirectTo(predicatePage: () => boolean, path: string): void {
    if (predicatePage()) return;

    this.$router.push({path});
    this.scrollToTop();
  }

  private redirectToHomePage(): void {
    this.redirectTo(this.isHomePage, this.homeUrl);
  }

  // private redirectToProjectRequestPage(): void {
  //   this.redirectTo(this.isProjectRequestPage, this.navItemsTop.projectRequest.url);
  // }

  private redirectToRecruitPage(): void {
    this.redirectTo(this.isRecruitPage, this.navItemsTop.recruit.url);
  }

  private redirectToMessagePage(): void {
    this.redirectTo(this.isMessagePage, this.navItemsTop.message.url);
  }

  private redirectToAccessPage(): void {
    this.redirectTo(this.isAccessPage, this.navItemsTop.access.url);
  }

  private isPage(siteUrl: string): boolean {
    const currentPageUrl = this.$route.path;

    return currentPageUrl === siteUrl;
  }

  private isHomePage(): boolean {
    return this.isPage(this.homeUrl);
  }

  // private isProjectRequestPage(): boolean {
  //   return this.isPage(this.navItemsTop.projectRequest.url);
  // }

  private isRecruitPage(): boolean {
    return this.isPage(this.navItemsTop.recruit.url);
  }

  private isMessagePage(): boolean {
    return this.isPage(this.navItemsTop.message.url);
  }

  private isAccessPage(): boolean {
    return this.isPage(this.navItemsTop.access.url);
  }
}
