import {RouteConfig} from "vue-router";
import Home from "@/views/Home.vue";
import Access from "@/views/Access.vue";
// import ProjectRequest from "@/views/ProjectRequest.vue";
import Recruit from "@/views/Recruit.vue";
import Message from "@/views/Message.vue";
import DesignAtoms from "@/views/DesignAtoms.vue";

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/project-request",
  //   name: "ProjectRequest",
  //   component: ProjectRequest,
  //   meta: { title: 'sample' },
  // },
  {
    path: "/recruit",
    name: "Recruit",
    component: Recruit,
    meta: { title: '株式会社Value 採用情報' },
  },
  {
    path: "/message",
    name: "Message",
    component: Message,
    meta: { title: '株式会社Value 代表挨拶' },
  },
  {
    path: "/access",
    name: "Access",
    component: Access,
    meta: { title: '株式会社Value オフィス所在地' },
  },
  {
    path: "/design-atoms",
    name: "DesignAtoms",
    component: DesignAtoms,
    meta: { title: '株式会社Value DesignAtoms' },
  },
];

export default routes;
