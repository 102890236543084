import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const customColors = {
  azure: '#009EF7',
  parisGreen: '#50CD89',
  radicalRed: '#F1416C',
  whiteLilac: '#F5F8FA',
};

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: customColors.azure,
        success: customColors.parisGreen,
        error: customColors.radicalRed,
        info: customColors.whiteLilac,
      },
    },
  },
});
