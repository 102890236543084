






import {Component, Vue} from "vue-property-decorator";
import HomeHeaderMainComponent from "@/components/home/header/HomeHeaderMainComponent.vue";

@Component({
  components: {HomeHeaderMainComponent},
})
export default class HomeHeaderComponent extends Vue {
}
