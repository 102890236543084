






import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";
import RecruitHeaderMessageComponent from "@/components/recruit/header/message/RecruitHeaderMessageComponent.vue";

@Component({
  components: {
    RecruitHeaderMessageComponent,
  },
})
export default class RecruitHeaderComponent extends Vue {
}
