






import {Component, Vue} from "vue-property-decorator";
import AccessHeaderMessageComponent from "@/components/access/header/AccessHeaderMessageComponent.vue";

@Component({
  components: {AccessHeaderMessageComponent},
})
export default class AccessHeaderComponent extends Vue {
}
