





import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class MessageFooterComponent extends Vue {
}
