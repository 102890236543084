



















import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";
import AtomButtonOk from "@/components/UI/atoms/button/AtomButtonOk.vue";
import AtomButtonMove from "@/components/UI/atoms/button/AtomButtonMove.vue";

@Component({
  components: {
    AtomButtonOk,
    AtomButtonMove,
  }
})
export default class RecruitFooterComponent extends Vue {
  private openIndeed(): void {
    window.open("https://jp.indeed.com/web%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E9%96%8B%E7%99%BA%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-b4c67747a6bbb563", "_blank", "noopener");
  }
  private openMynavi(): void {
    window.open("https://tenshoku.mynavi.jp/jobinfo-318067-1-2-1/", "_blank", "noopener");
  }
}
