






import Component from "vue-class-component";
import {Emit, Vue} from "vue-property-decorator";

@Component
export default class AtomsIconHamburgerMenu extends Vue {
  @Emit('click')
  private handleClick() {
    // イベント発火のみ
  }
}
