














import {Component, Vue} from "vue-property-decorator";
import RecruitHeaderComponent from "@/components/recruit/header/RecruitHeaderComponent.vue";
import RecruitBodyComponent from "@/components/recruit/body/RecruitBodyComponent.vue";
import RecruitFooterComponent from "@/components/recruit/footer/RecruitFooterComponent.vue";
import TemplateHeaderAndAside from "@/components/UI/templates/TemplateHeaderAndAside.vue";
import TemplateFooter from "@/components/UI/templates/TemplateFooter.vue";

@Component({
  components: {
    TemplateFooter,
    TemplateHeaderAndAside,
    RecruitFooterComponent,
    RecruitBodyComponent,
    RecruitHeaderComponent,
  },
})
export default class Recruit extends Vue {
}
