












import {Component, Vue} from "vue-property-decorator";
import AccessBodyMapComponent from "@/components/access/body/AccessBodyMapComponent.vue";
import AccessHeaderComponent
  from "@/components/access/header/AccessHeaderComponent.vue";
import TemplateHeaderAndAside from "@/components/UI/templates/TemplateHeaderAndAside.vue";
import TemplateFooter from "@/components/UI/templates/TemplateFooter.vue";

@Component({
  components: {
    TemplateFooter,
    TemplateHeaderAndAside,
    AccessHeaderComponent,
    AccessBodyMapComponent,
  },
})
export default class Access extends Vue {
}
