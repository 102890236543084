





















import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";

@Component
export default class MessageBodyMainMessageComponent extends Vue {
}
