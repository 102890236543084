














































import {Component, Prop, Vue} from "vue-property-decorator";
import {CommonNavItemType} from "@/type/common/nav";

@Component
export default class MoleculeAsideDrawer extends Vue {
  // data
  @Prop({required: true})
  private navItemsTop!: Record<string, CommonNavItemType>;
  @Prop({required: true})
  private navItemsDown!: Record<string, CommonNavItemType>;
}
