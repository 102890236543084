












import Component from "vue-class-component";
import {Emit, Prop, Vue} from "vue-property-decorator";

@Component
export default class AtomLogoValueHeader extends Vue {
  @Prop({type: Number, required: false, default: 80})
  private maxHeight!: number;
  @Prop({type: Number, required: false, default: 180})
  private maxWidth!: number;

  @Emit('click')
  private handleClick() {
    // イベント発火のみ
  }
}
