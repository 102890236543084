











import {Component, Vue} from "vue-property-decorator";
import HomeHeaderComponent from "@/components/home/header/HomeHeaderComponent.vue";
import HomeBodyComponent from "@/components/home/body/HomeBodyComponent.vue";
import TemplateHeaderAndAside from "@/components/UI/templates/TemplateHeaderAndAside.vue";
import TemplateFooter from "@/components/UI/templates/TemplateFooter.vue";

@Component({
  components: {
    TemplateFooter,
    TemplateHeaderAndAside,
    HomeBodyComponent,
    HomeHeaderComponent,
  },
})
export default class Home extends Vue {
}
