












import {Component, Vue} from "vue-property-decorator";
import AtomCardOnlyText from "@/components/UI/atoms/card/AtomCardOnlyText.vue";

@Component({
  components: {
    AtomCardOnlyText,
  }
})
export default class MoleculeFooter extends Vue {
}
