







import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";
import MessageBodyMainMessageComponent from "@/components/message/body/MessageBodyMainMessageComponent.vue";
import MessageBodyPresidentComponent from "@/components/message/body/MessageBodyPresidentComponent.vue";

@Component({
  components: {
    MessageBodyMainMessageComponent,
    MessageBodyPresidentComponent
  },
})
export default class MessageBodyComponent extends Vue {
}
