import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";

Vue.use(VueRouter);

const DEFAULT_TITLE = '株式会社Value';

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, ) => {
  document.title = to.meta ? (to.meta.title || DEFAULT_TITLE) : DEFAULT_TITLE;
});

export default router;
