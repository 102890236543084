






import {Component, Vue} from "vue-property-decorator";
import OrganismFooter from "@/components/UI/organisms/OrganismFooter.vue";

@Component({
  components: {OrganismFooter}
})
export default class CommonFooterComponent extends Vue {
  // data
  year = this.#getThisYear();

  // method
  #getThisYear(): number {
    return new Date().getFullYear();
  }
}
